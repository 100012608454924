/**
 * Ensure Abstraction class
 */
class Ensure {
    /**
     * Ensure value is Undefined
     * @param {*} value Value to be validated
     * @return {boolean}
     */
    static isUndefined(value = undefined) {
        return typeof value === 'undefined' ||
            value === null ||
            value === '' ||
            value === {} ||
            value === [];
        // || JSON.stringify(value) === JSON.stringify({});
    }

    /**
     * Ensure Input validity
     * @param {*} input to validate
     * @return {boolean} true if is valid
     */
    static isInputValid(input) {
        return !this.isUndefined(input) && input.checkValidity();
    }

    /**
     * Verify if object has properties
     * @param {Object} object - Object to be verified
     * @param {Array} props - properties to search on object
     * @return {Boolean} True if object has all properties
     */
    static hasProps(object, props) {
        const missingPropsResult = this.getMissingProps(object, props);
        return missingPropsResult.length <= 0;
    }

    /**
     * Get Object missing properties
     * @param {Object} object - Object to be verified
     * @param {Array} props - properties to search on object
     * @return {Array} missing properties
     */
    static getMissingProps(object, props) {
        let missingProps = [];
        [...props].forEach((prop) => {
            const result = prop in object;
            if (!result) {
                missingProps.push(prop);
            }
        });

        return missingProps;
    }
}

export default Ensure;
